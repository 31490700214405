import React from 'react';
import Routes from '../../routes';
import Footer from '../Footer';

const App = () => {
  return (
    <>
      <Routes />
      <Footer />
    </>
  );
}

export default App;
