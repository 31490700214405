import { connect } from 'react-redux'
import { win, lose } from '../../redux/score';
import { IAppReduxState } from '../../redux/store';

import Timer from './Timer';

const mapStateToProps = (state: IAppReduxState) => ({
  score: state.score.score
});

const mapDispatchToProps = {
  win,
  lose
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Timer);
