import { connect } from 'react-redux'
import { openQuestion } from '../../redux/questions';
import { IAppReduxState } from '../../redux/store';

import Wheel from './Wheel';

const mapStateToProps = (state: IAppReduxState) => ({
  questions: state.questions.questions
});

const mapDispatchToProps = {
  openQuestion
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Wheel);
