import React, { Component } from 'react';
import { History } from 'history';

// Models
import { IQuestion, IQuestionType, ISimpleQuestion } from '../../types/question';

import classes from './Main.module.scss';

type Props = {
  score: [number, number];
  setScore: (score: [number, number]) => void;
  resetScore: () => void;
  questions: IQuestion[];
  setQuestion: (question: IQuestion, index: number) => void;
  shuffleQuestions: () => void;
  resetQuestions: () => void;
  history: History;
};
type State = {
};

class Main extends Component<Props, State> {
  emptyQuestionArray: any[] = new Array(13).fill(null)
  private types: {
    label: string,
    value: IQuestionType
  }[] = [
    {
      label: 'Вопрос',
      value: IQuestionType.simple,
    },
    {
      label: 'Блиц',
      value: IQuestionType.blitz,
    },
    {
      label: 'Суперблиц',
      value: IQuestionType.super_blitz,
    },
  ];

  componentDidMount () {
    document.addEventListener('keydown', this.onKeyDown);
  }

  componentWillUnmount () {
    document.removeEventListener('keydown', this.onKeyDown);
  }

  onKeyDown = (e: KeyboardEvent): void => {
    switch (e.key) {
      case 'Enter':
        this.startGame();
        break;
    }
  };

  startGame = () => {
    this.props.history.push('/wheel');
  };

  resetQuestions = () => {
    this.props.resetQuestions();
  };

  resetScore = () => {
    this.props.resetScore();
  };

  getSelectOptions = (question: IQuestion): { label: string, value: IQuestionType }[] => {
    return this.types
      .filter((type) => {
        if (type.value === IQuestionType.simple) {
          return true;
        }

        if (type.value === question.type) {
          return true;
        }

        return !this.props.questions.find((cQuestion) => cQuestion.type === type.value);
      });
  };

  shuffleQuestions = (): void => {
    this.props.shuffleQuestions();
  }

  onChange = (index: number, fieldName: string, fieldValue: string | boolean): void => {
    const { questions, setQuestion } = this.props;

    setQuestion({
      ...questions[index],
      [fieldName]: fieldValue
    }, index);
  };

  setScore = (cScore: string, index: number) => {
    const { score, setScore } = this.props;

    const parsedScore = parseInt(cScore, 10);
    const resultScore = Math.min(6, Math.max(0, parsedScore || 0));
    const newScore: [number, number] = [score[0], score[1]];
    newScore[index] = resultScore;

    setScore(newScore);
  };

  render () {
    const { questions, score } = this.props;

    return (
      <div className={classes.Container}>
        <div className={classes.Form}>
          {
            this.emptyQuestionArray.map((m, index) => (
              <div
                key={index}
                className={classes.Row}
              >
                <div className={classes.Column}>
                  {index + 1}
                </div>
                <div className={classes.Column}>
                  <select
                    name='type'
                    className={classes.Select}
                    onChange={(e) => this.onChange(index, 'type', e.target.value)}
                    value={questions[index].type}
                  >
                    {
                      this.getSelectOptions(questions[index]).map((option) => (
                        <option
                          key={option.value}
                          value={option.value}
                        >
                          {option.label}
                        </option>
                      ))
                    }
                  </select>
                </div>
                <div className={classes.Column}>
                  <input
                    type='text'
                    className={classes.Field}
                    disabled={[IQuestionType.super_blitz, IQuestionType.blitz].includes(questions[index].type)}
                    value={
                      questions[index].type === IQuestionType.simple
                        ? (questions[index] as ISimpleQuestion).title
                        : ''
                    }
                    onChange={(e) => this.onChange(index, 'title', e.target.value)}
                  />
                </div>
                <div className={classes.Column}>
                  <input
                    type='checkbox'
                    className={classes.Checkbox}
                    checked={questions[index].opened}
                    onChange={(e) => this.onChange(index, 'opened', e.target.checked)}
                  />
                </div>
              </div>
            ))
          }
        </div>
        <div className={classes.SetupPanel}>
          <p className={classes.SetupPanelTitle}>Начальный счёт</p>
          <div className={classes.StartScore}>
            <div className={classes.StartScoreColumn}>
              <input
                type='text'
                className={classes.Field}
                value={score[0]}
                onKeyPress={(e) => this.setScore(e.key, 0)}
                onChange={() => {}}
              />
            </div>
            <div className={classes.StartScoreColumn}>
              :
            </div>
            <div className={classes.StartScoreColumn}>
              <input
                type='text'
                className={classes.Field}
                value={score[1]}
                onKeyPress={(e) => this.setScore(e.key, 1)}
                onChange={() => {}}
              />
            </div>
          </div>
          <button
            className='button'
            onClick={this.resetScore}
          >
            Сбросить счёт
          </button>
          <button
            className='button'
            onClick={this.resetQuestions}
          >
            Сбросить вопросы
          </button>
          <button
            className='button'
            onClick={this.shuffleQuestions}
          >
            Перемешать вопросы
          </button>
          <button
            className='button'
            onClick={this.startGame}
          >
            Запустить игру
          </button>
        </div>
      </div>
    );
  }
}

export default Main;
