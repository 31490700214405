import { connect } from 'react-redux'
import { IAppReduxState } from '../../redux/store';

import Score from './Score';

const mapStateToProps = (state: IAppReduxState) => ({
  questions: state.questions.questions,
  score: state.score.score
});

const mapDispatchToProps = {
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Score);
