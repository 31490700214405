import React, { Component } from 'react';

import classes from './Footer.module.scss';

class Footer extends Component {
  render() {
    return (
      <div className={classes.Footer}>
        <span className={classes.FooterLabel}>Сделано в</span>
        {' '}
        <a
          href='https://effective.band'
          className={classes.FooterLink}
        >
          <img
            src='/images/effective_logo.svg'
            alt='Effective'
            className={classes.FooterLogo}
          />
        </a>,{' '} а именно:{' '}
        <a
          href='https://valov.me'
          className={classes.FooterLink}
        >
          Данил Валов
        </a> и{' '}
        <a
          href='mailto:opengamer29@gmail.com'
          className={classes.FooterLink}
        >
          Евгений Комаров
        </a>
      </div>
    );
  }
}

export default Footer;
