import React, { Component } from 'react';
import { Power4, TweenMax } from 'gsap';
import { History } from 'history';

// Components
import ScorePanel from '../../components/ScorePanel';

// Helpers
import { getCurrentRotation } from '../../helpers/utils';
import { getRandomInt } from '../../helpers/generators';
import { playAudio, stopAudio } from '../../helpers/audio';

// Models
import { IQuestion, IQuestionType } from '../../types/question';

import cx from 'classnames';
import classes from './Wheel.module.scss';

type Props = {
  questions: IQuestion[];
  openQuestion: (question: IQuestion) => void;
  history: History;
};
type State = {
  questionStyles: { [key: string]: any };
  currentQuestion: IQuestion | null;
  currentQuestionStyles: { [key: string]: any };
};

export default class Wheel extends Component<Props, State> {
  state: State = {
    questionStyles: {},
    currentQuestion: null,
    currentQuestionStyles: {}
  };

  private arrowElement: HTMLDivElement | null = null;
  private questionsElements: { [key: string]: HTMLLIElement } = {};
  private questionNameWrappersElements: { [key: string]: HTMLParagraphElement } = {};
  private questionNamesElements: { [key: string]: HTMLSpanElement } = {};
  private currentQuestionElement: HTMLDivElement | null = null;

  private running: boolean = false;
  private questionOpened: boolean = false;
  private currentRotation: number = getRandomInt(0, 360);

  componentDidMount () {
    document.addEventListener('keydown', this.onKeyDown);
    this.resetRotation();

    setTimeout(() => {
      this.recalculateQuestionNamesSizes();
    }, 1);
  }

  componentWillUnmount () {
    document.removeEventListener('keydown', this.onKeyDown);
  }

  setArrowRef = (ref: HTMLDivElement) => {
    this.arrowElement = ref;
  };

  setQuestionRef = (questionIndex: number) => (ref: HTMLLIElement): void => {
    this.questionsElements[questionIndex] = ref;
  };

  setQuestionNameWrapperRef = (questionIndex: number) => (ref: HTMLParagraphElement): void => {
    this.questionNameWrappersElements[questionIndex] = ref;
  };

  setQuestionNameRef = (questionIndex: number) => (ref: HTMLSpanElement): void => {
    this.questionNamesElements[questionIndex] = ref;
  };

  setCurrentQuestionRef = (ref: HTMLDivElement): void => {
    this.currentQuestionElement = ref;
  }

  onKeyDown = (e: KeyboardEvent): void => {
    switch (e.key) {
      case ' ':
        this.startWheel();
        break;
      case 'Enter':
        this.openTimer();
        break;
      case 'Escape':
        this.onEscape();
        break;
    }
  };

  recalculateQuestionNamesSizes = (): void => {
    const { questions } = this.props;

    const questionStyles: { [key: string]: any } = {};

    questions.forEach((question, questionIndex) => {
      questionStyles[questionIndex] = {};

      if (question.opened) {
        return;
      }

      const wrapperWidth = this.questionNameWrappersElements[questionIndex].offsetWidth;
      const wrapperHeight = this.questionNameWrappersElements[questionIndex].offsetHeight;
      const textWidth = this.questionNamesElements[questionIndex].offsetWidth;
      const textHeight = this.questionNamesElements[questionIndex].offsetHeight;
      const coefficient = Math.min(wrapperWidth / textWidth, wrapperHeight / textHeight);

      if (coefficient < 1) {
        questionStyles[questionIndex].fontSize = (coefficient * 100) + '%';
      }
    });

    this.setState({
      questionStyles
    });
  };

  startWheel = (): void => {
    if (!this.hasActiveQuestions()) {
      this.props.history.push('/score');
    }

    this.startWheelAnimation();
  }

  resetRotation (): void {
    if (!this.arrowElement) {
      return;
    }

    TweenMax.to(this.arrowElement, 0, {
      rotation: this.currentRotation
    });
  }

  startWheelAnimation (): void {
    if (!this.arrowElement || this.running || this.questionOpened) {
      return;
    }

    this.running = true;
    const nextCurrentRotation = getRandomInt(7200, 14400);

    playAudio('volchok');

    TweenMax.fromTo(this.arrowElement, getRandomInt(20, 43), {
      rotation: this.currentRotation
    }, {
      rotation: nextCurrentRotation,
      ease: Power4.easeOut,
      onComplete: () => {
        this.currentRotation = nextCurrentRotation % 360;

        this.resetRotation();
        this.showSelectedQuestion();
      },
    });
  }

  openTimer = (): void => {
    playAudio('gong');

    this.props.history.push('/timer');
  }

  hasActiveQuestions (): boolean {
    return !!this.props.questions.filter((question) => (
      !question.opened &&
      (question.type !== IQuestionType.simple || question.title.length)
    )).length;
  }

  getSelectedQuestion (): IQuestion {
    const { questions } = this.props;
    const currentQuestionIndex = Math.floor((((this.currentRotation + (360 / 13 * 0.5))) % 360) / 360 * 13);
    const currentQuestion = [
      ...questions,
      ...questions
    ]
      .slice(currentQuestionIndex)
      .find((question) => (
        !question.opened &&
        (question.type !== IQuestionType.simple || question.title.length)
      ));

    return currentQuestion || questions[0];
  }

  showSelectedQuestion(): void {
    const question = this.getSelectedQuestion();
    this.showQuestion(question);
  }

  showQuestion(question: IQuestion): void {
    stopAudio('volchok');

    const { questions } = this.props;
    const { questionStyles } = this.state;

    const index = questions.findIndex(cQuestion => cQuestion === question);
    const _questionElement = this.questionsElements[index];
    const questionRotation = getCurrentRotation(_questionElement);
    const pPosition = _questionElement.querySelector('p')?.getBoundingClientRect();

    if (pPosition) {
      const top = pPosition.top + ((pPosition.bottom - pPosition.top) / 2);
      const left = pPosition.left + ((pPosition.right - pPosition.left) / 2);
      const rotation = questionRotation - 90;

      this.setState({
        currentQuestion: question,
        currentQuestionStyles: {
          top: `${top}px`,
          left: `${left}px`,
          transform: `rotate(${rotation}deg)`,
          fontSize: questionStyles[index].fontSize
        }
      }, () => {
        this.startOpenQuestionAnimation(question, {
          top,
          left,
          rotation
        });
      });
    }
  }

  startOpenQuestionAnimation(question: IQuestion, styles: { top: number, left: number, rotation: number }): void {
    if (!this.currentQuestionElement) {
      return;
    }

    const { openQuestion } = this.props;

    TweenMax.fromTo(this.currentQuestionElement, 1, {
      rotation: styles.rotation
    }, {
      rotation: styles.rotation > 180 ? 360 : 0,
      x: window.innerWidth / 2 - styles.left,
      y: window.innerHeight / 2 - styles.top,
      scale: 5,
      ease: Power4.easeOut,
      onComplete: () => {
        openQuestion(question);

        this.running = false;
        this.questionOpened = true;
      }
    });
  }

  openSetupScreen = (): void => {
    this.props.history.push('/');
  }

  onEscape(): void {
    if (this.running || !this.questionOpened) {
      this.openSetupScreen();

      return;
    }

    this.setState({
      currentQuestion: null,
      currentQuestionStyles: {}
    }, () => {
      this.questionOpened = false;
    });
  }

  openScoreScreen = (): void => {
    this.props.history.push('/score');
  }

  getQuestionTitle(question: IQuestion): string {
    switch (question.type) {
      case IQuestionType.blitz:
        return 'БЛИЦ БЛИЦ';
      case IQuestionType.super_blitz:
        return 'Суперблиц';
      case IQuestionType.simple:
        return question.title;
    }
  }

  render () {
    const { questions } = this.props;
    const {
      questionStyles,
      currentQuestion,
      currentQuestionStyles: { fontSize, ...otherCurrentQuestionStyles }
    } = this.state;

    return (
      <>
        <div className={classes.Wheel}>
          <ul className={classes.Lines}>
            {
              new Array(13).fill(null).map((m, key) => (
                <li
                  key={key}
                  className={classes.LinesItem}
                />
              ))
            }
          </ul>
          <ul className={classes.MoveArrows}>
            {
              new Array(13).fill(null).map((m, key) => (
                <li
                  key={key}
                  className={classes.MoveArrowsItem}
                />
              ))
            }
          </ul>
          <ul className={classes.Questions}>
            {
              questions.map((question, index) => (
                <li
                  key={index}
                  ref={this.setQuestionRef(index)}
                  className={cx(
                    classes.QuestionsItem,
                    {
                      [classes.QuestionsItemBlitz]: question.type === IQuestionType.blitz,
                      [classes.QuestionsItemSuperBlitz]: question.type === IQuestionType.super_blitz,
                      [classes.QuestionsItemOpened]: (
                        question.opened ||
                        question === currentQuestion ||
                        (
                          question.type === IQuestionType.simple && !question.title.length
                        )
                      )
                    }
                  )}
                  onClick={() => this.showQuestion(question)}
                >
                  <p
                    ref={this.setQuestionNameWrapperRef(index)}
                    className={classes.QuestionsTextWrapper}
                  >
                    <span
                      ref={this.setQuestionNameRef(index)}
                      className={classes.QuestionsText}
                      style={questionStyles[index]}
                    >
                      {this.getQuestionTitle(question)}
                    </span>
                  </p>
                </li>
              ))
            }
          </ul>
          <div
            ref={this.setArrowRef}
            className={classes.Arrow}
          />
          <ul
            className={classes.Roulette}
            onClick={this.startWheel}
          >
            {
              new Array(8).fill(null).map((m, key) => (
                <li
                  key={key}
                  className={classes.RouletteLine}
                />
              ))
            }
          </ul>
        </div>
        <div
          className={classes.Score}
          onClick={this.openScoreScreen}
        >
          <ScorePanel />
        </div>
        {
          currentQuestion ?
            <div
              ref={this.setCurrentQuestionRef}
              className={cx(
                classes.CurrentQuestion,
                {
                  [classes.CurrentQuestionBlitz]: currentQuestion.type === IQuestionType.blitz,
                  [classes.CurrentQuestionSuperBlitz]: currentQuestion.type === IQuestionType.super_blitz
                }
              )}
              style={otherCurrentQuestionStyles}
              onClick={this.openTimer}
            >
              <div className={classes.CurrentQuestionWrapper}>
                <p className={classes.CurrentQuestionTextWrapper}>
                  <span
                    className={classes.CurrentQuestionText}
                    style={{fontSize}}
                  >
                    {this.getQuestionTitle(currentQuestion)}
                  </span>
                </p>
              </div>
            </div>
            : null
        }
        <div
          className={ classes.SettingsBtn}
          onClick={this.openSetupScreen}
        >
          <i className='gear' />
        </div>
      </>
    );
  };
};
