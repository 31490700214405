const sounds = [
  'volchok',
  'gong',
  'signal_1',
  'signal_2',
  'signal_3',
  'win',
  'lose',
  'final'
] as const;

type Sound = typeof sounds[number];

let currentPlayer: HTMLAudioElement | null = null;
const players: { [key: string]: HTMLAudioElement } = {};
sounds.forEach(sound => {
  players[sound] = new Audio(`/sounds/${sound}.mp3`);
});

export const stopAudio = (sound?: Sound) => {
  return new Promise((resolve) => {
    let player: HTMLAudioElement | null = null;

    if (sound) {
      player = players[sound];

      if (currentPlayer) {
        (currentPlayer as HTMLAudioElement).pause();
      }
    } else if (currentPlayer) {
      player = (currentPlayer as HTMLAudioElement);
      currentPlayer = null;
    }

    if (!player) {
      resolve();

      return;
    }

    const cPlayer: HTMLAudioElement = player;

    const fadeAudio = setInterval(() => {
      if (cPlayer.volume <= 0) {
        clearInterval(fadeAudio);
        cPlayer.pause();
        cPlayer.currentTime = 0;

        resolve();

        return;
      }

      cPlayer.volume = Math.round((cPlayer.volume - 0.1) * 100) / 100;
    }, 50);
  });
};

const clearCurrentPlayer = () => {
  if (currentPlayer) {
    currentPlayer.removeEventListener('ended', clearCurrentPlayer);
    currentPlayer = null;
  }
}

export const playAudio = async (sound: Sound) => {
  await stopAudio();

  if (players[sound]) {
    currentPlayer = players[sound];
    currentPlayer.volume = 1.0;
    currentPlayer.currentTime = 0;
    currentPlayer.play();
    currentPlayer.addEventListener('ended', clearCurrentPlayer);
  }
};
