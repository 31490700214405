import { connect } from 'react-redux'
import { IAppReduxState } from '../../redux/store';

import ScorePanel from './ScorePanel';

const mapStateToProps = (state: IAppReduxState) => {
  return {
    score: state.score.score
  };
};

const mapDispatchToProps = {
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ScorePanel);
