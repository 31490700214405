// Helpers
import { shuffleArray } from '../helpers/utils';

// Models
import { IQuestion, IQuestionType } from '../types/question';

// ------------------------------------
// Constants
// ------------------------------------
export const SET_QUESTION = 'SET_QUESTION';
export const OPEN_QUESTION = 'OPEN_QUESTION';
export const SHUFFLE_QUESTIONS = 'SHUFFLE_QUESTIONS';
export const RESET_QUESTIONS = 'RESET_QUESTIONS';

export interface IReduxState {
  questions: IQuestion[];
}

interface SetQuestionAction {
  type: typeof SET_QUESTION;
  index: number;
  question: IQuestion;
}

interface OpenQuestionAction {
  type: typeof OPEN_QUESTION;
  question: IQuestion;
}

interface ShuffleQuestionsAction {
  type: typeof SHUFFLE_QUESTIONS;
}

interface ResetQuestionsAction {
  type: typeof RESET_QUESTIONS;
}

export type Action = SetQuestionAction | OpenQuestionAction | ShuffleQuestionsAction | ResetQuestionsAction;

// ------------------------------------
// Actions
// ------------------------------------
export const setQuestion = (question: IQuestion, index: number) => ({
  type: SET_QUESTION,
  question,
  index
});

export const openQuestion = (question: IQuestion) => ({
  type: OPEN_QUESTION,
  question
});

export const shuffleQuestions = () => ({
  type: SHUFFLE_QUESTIONS
});

export const resetQuestions = () => ({
  type: RESET_QUESTIONS
});

// ------------------------------------
// initialState
// ------------------------------------
const initialState: IReduxState = {
  questions: [
    {
      type: IQuestionType.simple,
      title: '',
      opened: false
    },
    {
      type: IQuestionType.simple,
      title: '',
      opened: false
    },
    {
      type: IQuestionType.simple,
      title: '',
      opened: false
    },
    {
      type: IQuestionType.simple,
      title: '',
      opened: false
    },
    {
      type: IQuestionType.simple,
      title: '',
      opened: false
    },
    {
      type: IQuestionType.simple,
      title: '',
      opened: false
    },
    {
      type: IQuestionType.simple,
      title: '',
      opened: false
    },
    {
      type: IQuestionType.simple,
      title: '',
      opened: false
    },
    {
      type: IQuestionType.simple,
      title: '',
      opened: false
    },
    {
      type: IQuestionType.simple,
      title: '',
      opened: false
    },
    {
      type: IQuestionType.simple,
      title: '',
      opened: false
    },
    {
      type: IQuestionType.simple,
      title: '',
      opened: false
    },
    {
      type: IQuestionType.simple,
      title: '',
      opened: false
    }
  ]
};

// ------------------------------------
// Reducer
// ------------------------------------
export default (state: IReduxState = initialState, action: Action): IReduxState => {
  switch (action.type) {
    case SET_QUESTION: {
      const questions = state.questions.slice();
      questions[action.index] = action.question;

      return {
        ...state,
        questions
      };
    }
    case OPEN_QUESTION: {
      return {
        ...state,
        questions: state.questions.map((question) => {
          if (action.question === question) {
            return {
              ...question,
              opened: true
            };
          }

          return question;
        })
      };
    }
    case SHUFFLE_QUESTIONS: {
      const questions = state.questions.slice();
      shuffleArray(questions);

      return {
        ...state,
        questions
      };
    }
    case RESET_QUESTIONS: {
      return {
        ...state,
        questions: initialState.questions
      };
    }
    default: {
      return state;
    }
  }
};
