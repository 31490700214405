import { connect } from 'react-redux'
import { setQuestion, shuffleQuestions, resetQuestions } from '../../redux/questions';
import { setScore, resetScore } from '../../redux/score';
import { IAppReduxState } from '../../redux/store';

import Main from './Main';

const mapStateToProps = (state: IAppReduxState) => ({
  questions: state.questions.questions,
  score: state.score.score
});

const mapDispatchToProps = {
  setQuestion,
  shuffleQuestions,
  resetQuestions,
  setScore,
  resetScore
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Main);
