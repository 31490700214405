import React, { Component } from 'react';

import classes from './ScorePanel.module.scss';

type Props = {
  score: [number, number];
};
type State = {
};

class ScorePanel extends Component<Props, State> {
  render () {
    const { score } = this.props;

    return (
      <div className={classes.ScorePanel}>
        <div className={classes.ScorePanelColumn}>
          <p className={classes.ScorePanelLabel}>
            Знатоки
          </p>
          <p className={classes.ScorePanelValue}>
            {score[0]}
          </p>
        </div>
        <div className={classes.ScorePanelColumn}>
          <p className={classes.ScorePanelLabel}>
            Зрители
          </p>
          <p className={classes.ScorePanelValue}>
            {score[1]}
          </p>
        </div>
      </div>
    );
  }
}

export default ScorePanel;
