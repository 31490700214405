import React, { Component } from 'react';
import { History } from 'history';

import Countdown, { CountdownApi, CountdownRenderProps, zeroPad } from 'react-countdown';

import cx from 'classnames';
import classes from './Timer.module.scss';
import { playAudio } from '../../helpers/audio';
import { CountdownTimeDelta } from 'react-countdown/dist/utils';

type Props = {
  score: [number, number];
  history: History;
  win: () => void;
  lose: () => void;
};
type State = {
  date: Date | number;
  hasResult: boolean;
};

export default class Timer extends Component<Props, State> {
  state = {
    date: Date.now() + 60000,
    hasResult: false
  };

  private timerApi: CountdownApi | null = null;

  componentDidMount () {
    document.addEventListener('keydown', this.onKeyDown);
  }

  componentWillUnmount () {
    document.removeEventListener('keydown', this.onKeyDown);
  }

  onKeyDown = (e: KeyboardEvent): void => {
    switch (e.key) {
      case ' ':
        this.toggleTimer();
        break;
      case 'Enter':
        this.pause();
        break;
      case 'Escape':
        this.goPrevState();
        break;
    }
  };

  goPrevState = () => {
    const { history } = this.props;
    const { hasResult } = this.state;

    if (hasResult) {
      this.setState({
        hasResult: false
      });
    } else {
      history.goBack();
    }
  }

  toggleTimer = () => {
    if (this.timerApi?.isPaused()) {
      this.timerApi?.start();
      playAudio('signal_1');
    } else {
      this.timerApi?.pause();
    }
  }

  renderer = (props: CountdownRenderProps) => {
    const { api, minutes, seconds } = props;

    this.timerApi = api;

    return (
      <div
        className={cx(
          classes.Timer,
          {
            [classes.TimerStarted]: !api.isPaused()
          }
        )}
      >
        {zeroPad(Math.min(minutes * 60 + seconds, 60))}
      </div>
    );
  };

  pause = (): void => {
    const { hasResult } = this.state;

    if (!this.timerApi?.isPaused()) {
      this.timerApi?.pause();
    }

    if (!hasResult) {
      this.setState({
        hasResult: true
      });
    }
  }

  onTimerTick = (delta: CountdownTimeDelta): void => {
    if (delta.seconds === 10) {
      playAudio('signal_2');
    }
  }

  onTimerComplete = (): void => {
    playAudio('signal_3');

    this.setState({
      hasResult: true
    });
  }

  saveResult = (isWin: boolean) => {
    const { score, history, win, lose } = this.props;

    if (isWin && score[0] < 5) {
      playAudio('win');
    } else if (!isWin && score[1] < 5) {
      playAudio('lose');
    }

    (isWin ? win : lose)();
    history.push('/score');
  };

  render () {
    const { date, hasResult } = this.state;

    return (
      <div className={classes.Container}>
        <Countdown
          autoStart={false}
          date={date}
          renderer={this.renderer}
          onTick={this.onTimerTick}
          onComplete={this.onTimerComplete}
        />
        {
          !hasResult
            ? <>
              <button
                className='button'
                onClick={this.toggleTimer}
              >
                Время
              </button>
              <button
                className='button'
                onClick={this.pause}
              >
                Досрочный ответ
              </button>
            </>
            : <>
              <button
                className='button'
                onClick={() => this.saveResult(true)}
              >
                Верно
              </button>
              <button
                className='button'
                onClick={() => this.saveResult(false)}
              >
                Не верно
              </button>
            </>

        }
      </div>
    );
  }
}
