import { applyMiddleware, compose, createStore, combineReducers, Store, Middleware } from 'redux';
import * as storage from 'redux-storage';
import createEngine from 'redux-storage-engine-indexed-db';

import questions, * as QuestionsNS from './questions';
import score, * as ScoreNS from './score';

export interface IAppReduxState {
  questions: QuestionsNS.IReduxState;
  score: ScoreNS.IReduxState;
}

type Action = QuestionsNS.Action | ScoreNS.Action;

const engine = createEngine('store');

// @ts-ignore
const rootReducer = storage.reducer(combineReducers({
  questions,
  score
}));

const initialState = {};
const enhancers = [];
const middleware: Middleware[] = [
  storage.createMiddleware(engine),
];

if (process.env.NODE_ENV === 'development') {
  if (typeof (window as any).devToolsExtension === 'function') { // todo remove any
    enhancers.push((window as any).devToolsExtension());
  }
}

const composedEnhancers = compose(
  applyMiddleware(...middleware),
  ...enhancers,
);

// @ts-ignore
const store: Store<IAppReduxState, Action> = createStore(
  rootReducer,
  initialState,
  composedEnhancers,
);

// @ts-ignore
storage.createLoader(engine)(store);

export default store;
