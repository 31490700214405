// ------------------------------------
// Constants
// ------------------------------------
export const SET_SCORE = 'SET_SCORE';
export const WIN = 'WIN';
export const LOSE = 'LOSE';
export const RESET = 'RESET';

export interface IReduxState {
  score: [number, number];
}

interface SetScoreAction {
  type: typeof SET_SCORE;
  score: [number, number];
}

interface WinAction {
  type: typeof WIN;
}

interface LoseAction {
  type: typeof LOSE;
}

interface ResetAction {
  type: typeof RESET;
}

export type Action = SetScoreAction | WinAction | LoseAction | ResetAction;

// ------------------------------------
// Actions
// ------------------------------------
export const setScore = (score: [number, number]) => ({
  type: SET_SCORE,
  score
});

export const win = () => ({
  type: WIN
});

export const lose = () => ({
  type: LOSE
});

export const resetScore = () => ({
  type: RESET
});

// ------------------------------------
// initialState
// ------------------------------------
const initialState: IReduxState = {
  score: [0, 0]
};

// ------------------------------------
// Reducer
// ------------------------------------
export default (state: IReduxState = initialState, action: Action): IReduxState => {
  switch (action.type) {
    case SET_SCORE: {
      return {
        ...state,
        score: action.score
      };
    }
    case WIN: {
      return {
        ...state,
        score: [state.score[0] + 1, state.score[1]]
      };
    }
    case LOSE: {
      return {
        ...state,
        score: [state.score[0], state.score[1] + 1]
      };
    }
    case RESET: {
      return {
        ...state,
        score: [0, 0]
      };
    }
    default: {
      return state;
    }
  }
};
