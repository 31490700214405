import React from 'react';
import { Switch, Route, BrowserRouter } from 'react-router-dom';

// Components
import Main from './Main';
import Wheel from './Wheel';
import Timer from './Timer';
import Score from './Score';

const Routes = () => (
  <BrowserRouter>
    <Switch>
      <Route
        exact
        path="/wheel"
        component={Wheel}
      />
      <Route
        exact
        path="/timer"
        component={Timer}
      />
      <Route
        exact
        path="/score"
        component={Score}
      />
      <Route
        exact
        path="/"
        component={Main}
      />
    </Switch>
  </BrowserRouter>
);

export default Routes;
