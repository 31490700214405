export const getCurrentRotation = (el: HTMLElement): number => {
  const st = window.getComputedStyle(el, null);
  const tm = st.getPropertyValue('-webkit-transform') ||
    st.getPropertyValue('-moz-transform') ||
    st.getPropertyValue('-ms-transform') ||
    st.getPropertyValue('-o-transform') ||
    st.getPropertyValue('transform') ||
    'none';

  if (tm !== 'none') {
    const values = tm.split('(')[1].split(')')[0].split(',');
    const angle = Math.round(Math.atan2(Number(values[1]), Number(values[0])) * (180 / Math.PI));

    return (angle < 0 ? angle + 360 : angle);
  }

  return 0;
}

export const pause = async (ms: number = 0): Promise<void> => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve();
    }, ms)
  });
}

export const shuffleArray = (array: any[]) => {
  array.sort(() => Math.random() - 0.5);
};
