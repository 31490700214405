export enum IQuestionType {
  simple = 'simple',
  blitz = 'blitz',
  super_blitz = 'super_blitz'
}

interface IQuestionCore {
  type: IQuestionType;
  opened: boolean;
}

export interface ISimpleQuestion extends IQuestionCore{
  type: IQuestionType.simple;
  title: string;
}

export interface IBlitzQuestion extends IQuestionCore{
  type: IQuestionType.blitz;
}

export interface ISuperBlitzQuestion extends IQuestionCore{
  type: IQuestionType.super_blitz;
}

export type IQuestion = ISimpleQuestion | IBlitzQuestion | ISuperBlitzQuestion;
