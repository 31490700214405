import React, { Component } from 'react';
import { History } from 'history';

import ScorePanel from '../../components/ScorePanel';

import classes from './Score.module.scss';
import { IQuestion, IQuestionType } from '../../types/question';
import { playAudio } from '../../helpers/audio';

type Props = {
  questions: IQuestion[];
  score: [number, number];
  history: History;
};
type State = {
};

class Score extends Component<Props, State> {
  componentDidMount () {
    document.addEventListener('keydown', this.onKeyDown);

    if (this.props.score[0] >= 6 || this.props.score[1] >= 6) {
      playAudio('final');
    }
  }

  componentWillUnmount () {
    document.removeEventListener('keydown', this.onKeyDown);
  }

  onKeyDown = (e: KeyboardEvent): void => {
    switch (e.key) {
      case 'Enter':
        this.onEnter();
        break;
      case 'Escape':
        this.nextQuestion();
        break;
    }
  };

  onEnter(): void {
    const { score } = this.props;

    const hasActiveQuestions = this.hasActiveQuestions();

    if (
      score[0] < 6 && score[1] < 6 &&
      hasActiveQuestions
    ) {
      this.nextQuestion();

      return;
    }

    if (!hasActiveQuestions) {
      this.stopGame();
    }
  }

  nextQuestion = () => {
    this.props.history.push('/wheel');
  };

  stopGame = () => {
    this.props.history.push('/');
  };

  hasActiveQuestions (): boolean {
    return !!this.props.questions.filter((question) => (
      !question.opened &&
      (question.type !== IQuestionType.simple || question.title.length)
    )).length;
  }

  render () {
    const { score } = this.props;

    const hasActiveQuestions = this.hasActiveQuestions();

    return (
      <div className={classes.Score}>
        <ScorePanel />
        {
          (
            score[0] < 6 && score[1] < 6 &&
            hasActiveQuestions
          ) ?
            <button
              className='button'
              onClick={this.nextQuestion}
            >
              Следующий вопрос
            </button>
            : null
        }
        {
          !hasActiveQuestions || (score[0] >= 6 || score[1] >= 6) ?
            <button
              className='button'
              onClick={this.stopGame}
            >
              Закончить игру
            </button>
            : null
        }
      </div>
    );
  }
}

export default Score;
